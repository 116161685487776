import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import bgImage from "../assets/images/marvinmeyer2sdjvx5jezqunsplash.jpg";
import favicon from "../assets/i/favicon.png";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <title>Okroo Website</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={favicon} type="image/x-icon" />
        {/* <!-- Google Fonts --> */}
        <link
          href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@100;200;300;400;500;600;700;800;900&family=DM+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap"
          rel="stylesheet"
        />
        {/* <!-- FontAwesome CSS --> */}
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css"
        />
        {/* <!-- AOS 2.3.4 jQuery plugin CSS (Animations) --> */}
        {/* <link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet"></link> */}
        {/* <!-- jQuery 3.5.1 -->
		    <script src="https://code.jquery.com/jquery-3.5.1.min.js"></script> */}
      </Helmet>
      <nav className="pt-30 pb-30 bg-dark1 lh-40 text-center navigation_21">
        <div className="container px-xl-0">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-3 text-lg-left">
              <StaticImage
                placeholder="none"
                src="./../assets/images/logowhite.png"
                alt="Logo"
                data-aos="fade-down"
                data-aos-delay="250"
              />
            </div>
            <div
              className="mt-10 mt-lg-0 col-lg-9 text-lg-right"
            // data-aos="fade-down"
            // data-aos-delay="250"
            // style={{height: '40px',width: '300px',}}
            >
              <a
                href="https://app.okroo.com/sign-up"
                className="btn sm border-transparent-white f-16 mx-0"
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  fontWeight: "500",
                  borderRadius: "50px",
                  padding: "0px 30px",
                  transition: "all 0.25s",
                  height: "50px",
                  lineHeight: "50px",
                  verticalAlign: "top",
                  color: "#fff",
                }}
              >
                Business Signup
              </a>
              <a
                href="https://app.okroo.com/login"
                className="btn action-1 f-16 sm ml-3"
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  fontWeight: "500",
                  borderRadius: "50px",
                  border: "none",
                  padding: "0px 30px",
                  transition: "all 0.25s",
                  height: "50px",
                  lineHeight: "50px",
                  verticalAlign: "top",
                }}
              >
                Business Login
              </a>
            </div>
          </div>
        </div>
      </nav>
      {/* <!-- Application 12 --> */}
      <section
        style={{ backgroundImage: `url(${bgImage})` }}
        className="pt-100 pb-100 application_12 bg-dark1 color-filter-dark-8"
        data-bg-src={bgImage}
      >
        <div className="container px-xl-0">
          <div className="row justify-content-center align-items-center">
            <div className="mb-30 mb-md-0 col-md-5 col-lg-6 d-flex justify-content-center justify-content-md-center">
              <StaticImage
                src="./../assets/images/phonescreen1x.png"
                srcSet="./../assets/images/phonescreen2x.png 2x"
                placeholder="none"
                alt=""
                imgClassName="img-fluid"
              />
            </div>
            <div className="col-md-7 col-lg-6 no-gutters">
              <h3 className="pb-25 w-400 pr-4 text-center color-white">
                Exclusive promotions from your favorite local business at any time of the day
              </h3>
              <div className="col-lg-10 col-xl-9 pb-40 f-18 medium text-center text-adaptive w-400 color-white">
                We work directly with your favorite local businesses to make sure these truly are the best promotions available. You won’t find these offers anywhere else.
              </div>
              <div className="d-flex flex-sm-row justify-content-start pb-5">
                <div className="mr-0 mr-sm-2 d-flex justify-content-start justify-content-sm-center align-items-center pb-lg-4">
                  <a
                    href="https://apps.apple.com/us/app/okroo/id1484597572"
                    className="link img_link radius6"
                  >
                    <StaticImage
                      placeholder="none"
                      src="./../assets/i/application_12_img_2.png"
                      srcSet="./../assets/i/application_12_img_2@2x.png 2x"
                      alt=""
                      imgClassName="img-fluid"
                    />
                  </a>
                </div>
                <div className="ml-4 d-flex justify-content-start justify-content-sm-center align-items-center pb-lg-4">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.okrooapp"
                    className="link img_link radius6"
                  >
                    <StaticImage
                      placeholder="none"
                      src="./../assets/i/application_12_img_3.png"
                      srcSet="./../assets/i/application_12_img_3@2x.png 2x"
                      alt=""
                      imgClassName="img-fluid"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-10 col-xl-9 d-flex justify-content-start align-items-center">
                <StaticImage
                  placeholder="none"
                  src="./../assets/images/iclauncherapp.png"
                  alt=""
                  imgClassName="img-fluid radius4 radius14"
                  imgStyle={{
                    width: "90px",
                    height: "90px",
                    marginRight: "-20px",
                  }}
                />
                <div
                  className="ml-15 f-14 mt-35 medium text-adaptive color-white"
                  data-aos="fade-down"
                  data-aos-delay="500"
                >
                  The Okroo app is available for Apple iPhone and Google Android
                  devices
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Feature 1 --> */}
      <section
        className="pt-105 pb-45 text-center feature_1 bg-light"
        data-bg-src="false"
      >
        <div className="container px-xl-0">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <h2
                className="mb-45 small"
                style={{ color: "#6b6394" }}
                data-aos="fade-down"
                data-aos-delay="0"
              >
                How it works
              </h2>
              <div className="row justify-content-center">
                <div
                  className="col-md-4 mb-50"
                  data-aos="fade-down"
                  data-aos-delay="250"
                >
                  <i
                    className="f-60 fas fa-map-marked-alt action-1"
                    data-bg-src="false"
                  ></i>
                  <div className="mt-20 mb-25 f-22 title bold action-3">
                    Discover
                  </div>
                  <div className="text-adaptive action-3">
                    Discover exclusive promotions from your favorite local businesses.
                  </div>
                </div>
                <div
                  className="col-md-4 mb-50"
                  data-aos="fade-down"
                  data-aos-delay="500"
                >
                  <i className="f-60 fas fa-tags action-1"></i>
                  <div className="mt-20 mb-25 f-22 title bold action-3">
                    Pre-purchase
                  </div>
                  <div className="text-adaptive action-3">
                    Pre-purchase by ordering ahead or at the local business, directly through the app. These promotions are exclusive to Okroo.
                  </div>
                </div>
                <div
                  className="col-md-4 mb-50"
                  data-aos="fade-down"
                  data-aos-delay="750"
                >
                  <i className="f-60 fas fa-mobile-alt action-1"></i>
                  <div className="mt-20 mb-25 f-22 title bold action-3">
                    Redeem
                  </div>
                  <div className="text-adaptive action-3">
                    Redeem your pre-purchase order from a staff member at the local business
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Content 8 --> */}
      <section className="pt-105 pb-15 content_8 bg-gray" data-bg-src="false">
        <div className="container px-xl-0">
          <div className="row justify-content-center text-center">
            <div className="col-lg-8 col-md-10">
              <h2
                className="small color-white pb-10"
                data-aos="fade-down"
                data-aos-delay="0"
              >
                Save money and time from the places you love—or discover
                exclusive promotions somewhere new
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-70 content_8 bg-indigo" data-bg-src="false">
        <div className="container text-center">
          <div className="col-md-12"
            data-aos="fade-down"
            data-aos-delay="0">
            <div className="color-white">
              If you are a business and are interested in partnering with us or learning more about our service, please send an email to craig@okroo.com

            </div>
          </div>
        </div>
      </section>
      {/* <!-- Footer 1 --> */}
      <footer className="pt-25 pb-25 bg-light text-center footer_1">
        <div className="container px-xl-0">
          <div className="row justify-content-between align-items-center lh-40 links">
            <div className="mb-10 mb-lg-0 col-lg-auto order-0">
              <h6 style={{ textAlign: "center" }}>
                <a
                  style={{ fontWeight: 100 }}
                  href="https://app.okroo.com/terms-and-conditions"
                  className="link color-main img_link"
                >
                  Terms and Conditions
                </a>
                {` | `}
                <a
                  style={{ marginLeft: "5px", fontWeight: 100 }}
                  href="https://app.okroo.com/privacy-policy"
                  className="link color-main img_link"
                >
                  Privacy Policy
                </a>
              </h6>
            </div>
            <div className="col-lg-4 col-sm-6 text-sm-left text-lg-right order-2 order-lg-0">
              <a href="mailto:info@okroo.com" className="link mr-15 action-1">
                info@okroo.com
              </a>
              <a
                href="https://www.instagram.com/okrooapp/"
                className="mx-15 link color-main img_link"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                href="https://www.facebook.com/okrooapp"
                className="mx-15 link color-main img_link"
              >
                <i className="fab fa-facebook-square"></i>
              </a>
              <a
                href="https://twitter.com/OkrooApp"
                className="ml-15 link color-main img_link"
              >
                <i className="fab fa-twitter"></i>
              </a>
            </div>
          </div>
          <div className="row justify-content-center">
            <div
              className="mt-10 col-xl-4 col-lg-5 col-md-6 col-sm-8"
              data-aos="fade-down"
              data-aos-delay="0"
            ></div>
          </div>
        </div>
      </footer>
      {/* <!-- forms alerts --> */}
      <div
        className="alert alert-success alert-dismissible fixed-top alert-form-success"
        role="alert"
      >
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        Thanks for your message!
      </div>
      <div
        className="alert alert-warning alert-dismissible fixed-top alert-form-check-fields"
        role="alert"
      >
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        Please, fill in required fields.
      </div>
      <div
        className="alert alert-danger alert-dismissible fixed-top alert-form-error"
        role="alert"
      >
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="message">
          An error occurred while sending data :( Please, check if your hosting
          supports PHP and check how to set form data sending{" "}
          <a
            rel="noreferrer"
            href="https://designmodo.com/startup/documentation/#form-handler"
            target="_blank"
            className="link color-transparent-white"
          >
            here
          </a>
          .
        </div>
      </div>
    </>
  );
};

export default IndexPage;
